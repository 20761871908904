<template>
  <div class="card p-shadow-6">
    <h1>Pesquisar Produtos</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />

    <Divider />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6 p-md-2 p-mt-4">
        <span class="p-float-label">
          <Dropdown
            name="search-by-category"
            v-model="categorySearch"
            :options="categories"
            :filter="true"
            :showClear="true"
          >
          </Dropdown>
          <label for="search-by-category">Por Categoria</label>
        </span>
      </div>

      <div class="p-field p-col-6 p-md-1 p-mt-4">
        <Button
          label="Pesquisar"
          icon="pi pi-search"
          class="p-button-sm"
          @click="searchByCategory"
        />
      </div>
      <Divider layout="vertical" />
      <div class="p-field p-col-6 p-md-3 p-mt-4">
        <span class="p-float-label">
          <InputText name="search" type="text" v-model="search" />
          <label for="search">Por Designação/Referência</label>
        </span>
      </div>
      <div class="p-field p-col-6 p-md-1 p-mt-4">
        <Button
          label="Pesquisar"
          icon="pi pi-search"
          class="p-button-sm"
          @click="searchProduct"
        />
      </div>
        <Divider layout="vertical" />
      <div class="p-field p-col-6 p-md-1 p-mt-4">
          <span
            class="p-float-label"
            style="top: -0.75rem; left: -1rem; font-size: 12px"
          >
            <label for="private">Sem stock</label>
          </span>
          <InputSwitch
            name="private"
            v-model="showNoStock"
            class="p-mt-1"
            @change="filtersProducts"
          />
        </div>
    </div>
    <DataTable
      :value="productsToGrid"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="isLoading"
      class="p-datatable-sm p-mt-3"
      dataKey="id"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="reference"
        header="Referência"
        sortable
        filterField="reference"
      >
        <template #body="slotProps">
          <span class="p-column-title">Referência:</span>
          {{ slotProps.data.reference }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>

      <Column
        field="designation"
        header="Designação"
        sortable
        filterField="designation"
      >
        <template #body="slotProps">
          <span class="p-column-title">Designação:</span>
          {{ slotProps.data.designation }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>

      <Column
        field="family"
        header="Categoria"
        sortable
        filterField="family"
      >
        <template #body="slotProps">
          <span class="p-column-title">Categoria:</span>
          {{ slotProps.data.family }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            :options="getCategorySearchOpt()"
            :filter="true"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column
        field="warehouse"
        header="Armazem"
        sortable
        filterField="warehouse"
      >
        <template #body="slotProps">
          <span class="p-column-title">Armazem:</span>
          {{ slotProps.data.warehouse }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            :options="getWarehouseSearchOpt()"
            :filter="true"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column
        field="stock"
        header="Quantidade"
        sortable
        filterField="stock"
        dataType="numeric"
      >
        <template #body="slotProps">
          <span class="p-column-title">Quantidade:</span>
          {{ slotProps.data.stock }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import productsService from "../services/products.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "ProductSearch",
  data() {
    return {
      isLoading: true,
      categories: [],
      categorySearch: null,
      search: null,
      products: [],
      productsToGrid: [],
      filters: {},
      showNoStock: true
    };
  },
  async created() {
    this.initFilters()
    this.categories = await this.getCategories();
    this.isLoading = false;
  },
  methods: {
    initFilters () {
      this.filters = {
        reference: { value: null, matchMode: FilterMatchMode.CONTAINS },
        designation: { value: null, matchMode: FilterMatchMode.CONTAINS },
        family: { value: null, matchMode: FilterMatchMode.EQUALS },
        warehouse: { value: null, matchMode: FilterMatchMode.EQUALS },
        stock: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    getCategories() {
      return productsService.getCategories();
    },
    searchByCategory() {
      this.search = null;
      this.initFilters();
      if (this.categorySearch == null) {
        return this.$toast.add({
          severity: "error",
          summary: "Categoria vazia",
          detail: "Primeiro selecione uma categoria",
          life: 5000,
        });
      }
      this.isLoading = true;
      return productsService
        .findByCategory({ category: this.categorySearch })
        .then((response) => {
          this.isLoading = false;
          this.products = response
          return this.filtersProducts();
        });
    },
    searchProduct() {
      this.categorySearch = null;
      this.initFilters();
      if (this.search == null) {
        return this.$toast.add({
          severity: "error",
          summary: "Campo vazio",
          detail: "Insira o termo que pretende pesquisar",
          life: 5000,
        });
      }
      this.isLoading = true;
      return productsService.find({ search: this.search }).then((response) => {
        this.isLoading = false;
        this.products = response
        return this.filtersProducts();
      });
    },
    getCategorySearchOpt() {
      if (this.products.length == 0) {
        return [];
      }
      let categoriesAux = this.products.map((o) => o.family);
      var categories = this.products.filter(
        ({ family }, index) => !categoriesAux.includes(family, index + 1)
      );
      return categories.map((x) => x.family);
    },
    getWarehouseSearchOpt() {
      if (this.products.length == 0) {
        return [];
      }
      let warehouseAux = this.products.map((o) => o.warehouse);
      var warehouses = this.products.filter(
        ({ warehouse }, index) => !warehouseAux.includes(warehouse, index + 1)
      );
      return warehouses.map((x) => x.warehouse);
    },
    filtersProducts() {
      if (this.showNoStock) {
        return this.productsToGrid = this.products;
      }
      return this.productsToGrid =  this.products.filter((product) => product.stock > 0)
    },
  },
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100%;
}
</style>
